<!--
 * ======================================
 * 说明： 移动端主体
 * 作者： Silence
 * 文件： layout.vue
 * 日期： 2023/7/21 21:58
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
    <router-view></router-view>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>